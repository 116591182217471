@import '../../scss/variables';
@import '../../scss/base';
@import '../../scss/buttons';

.user_card {
    min-height: 400px;
    width: 350px;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

}

.brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -75px;
    border-radius: 50%;
    background: $brand-primary-light;
    padding: 9px;
    text-align: center;
    border: 2px solid #fff
}

.brand_logo {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.form_container {
    margin-top: 100px;
    text-align: center;
}

.login_title {
    color: #fff !important;
    font-weight: 700 !important;
}

.login_container {
    padding: 0 2rem;
}

.input_group_text {
    background: $brand-accent !important;
    color: $brand-primary-dark !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
}

.input_user,
.input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}