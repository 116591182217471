.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #c0392b !important;
}

.cta_button {
    width: 100%;
    background: $brand-accent !important;
    color: #000 !important;
}

.cta_button:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.btn-brand-accent,
.btn_brand_accent {
    background: $brand-accent;
}

.bg-brand-accent-dark {
    background: $brand-accent-dark;
}