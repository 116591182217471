.badge-status {
    border: 1px solid transparent;
}

.badge-status:hover {
    border: 1px solid #000;
}

td .badge-status {
    min-width: 150px;
}

.badge-status-unreviewed {
    background-color: #9ea7ad;
    color: #000;
}

.badge-status-reviewed {
    background-color: #fff;
    color: #000;
    border-color: #888;
}

.badge-status-interview-scheduled, .badge-status-interviewed {
    background-color: #2dccff;
    color: #000;
}

.badge-status-offer-sent, .badge-status-hired {
    background-color: #56f000;
    color: #000;
}

.badge-status-declined-offer {
    background-color: #232323;
    color: #fff;
}

.badge-status-rejected {
    background-color: #ff3838;
    color: #fff;
}