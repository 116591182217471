$main-background-color: #fff;
$brand-primary-light: #b01d16;
$brand-primary-dark: #1c1b20;
$brand-accent: #def4f0;
$brand-accent-dark: #5998a7;

$tiny-screen: 320px;
$large-mobile: 420px;
$tablet-size: 640px;
$large-tablet: 768px;
$wide-screens: 992px;

$bootstrap-dark: #343a40;

$wide-screen-font-size: 16px;
$large-tablet-font-size: 14px;