@media screen {
.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    background: #fff;
    z-index: 999;
    border-bottom: 2px solid $brand_accent;
    -webkit-box-shadow: 0px 15px 10px -15px #111;
    box-shadow: 0px 15px 10px -15px #111;
  }
}

.thumbNav {
    background: $brand-accent-dark;
    
    a {
        color: #fff;
    }

    a:hover {
        color: $brand-primary-dark !important;
        text-decoration: underline;
    }
}