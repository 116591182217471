body {
    background: $main-background-color;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bg-brand-dark,
.bg_brand_dark {
    background-color: $brand-primary-dark !important;
}

.bg_brand_primary_light {
    background-color: $brand-primary-light !important;
}

.bg_brand_accent {
    background: $brand-accent !important;
    color: $brand-primary-dark !important;
}

.text_brand_primary {
    color: $brand-primary-light;
    font-weight: 700;
    text-shadow: #e0e0e0 1px 1px 0;
    //text-shadow: 0 -1px 4px #fff, 0 -2px 10px $brand-accent;
}

.text_brand_accent {
    color: $brand-accent;
}

a {
    color: $brand-accent-dark;
}

.btn-link {
    color: $brand-accent-dark;
}

#user-dropdown .dropdown-menu.show {
    background-color: $bootstrap-dark;
}

.applicantNameTop {
    max-width: 80ch;
    text-overflow: ellipsis;
    overflow: hidden;
}

.applicantTable {
    min-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.applicantTable-applicant {
    max-width: 40ch;
    text-overflow: ellipsis;
    overflow: hidden;
}

.applicantTable-applicant a {
    font-weight: bold;
}

.subtle-box-shadow {
    -webkit-box-shadow: 3px 3px 15px 3px rgba(0,0,0,0.23);
    box-shadow: 3px 3px 15px 3px rgba(0,0,0,0.23);
}

.bs-canvas-overlay {
    opacity: 0.85;
    z-index: 1100;
}

.bs-canvas {
    top: 4em;
    z-index: 1110;
    overflow-x: hidden;
    overflow-y: auto;
    width: 330px;
    transition: margin .4s ease-out;
    -webkit-transition: margin .4s ease-out;
    -moz-transition: margin .4s ease-out;
    -ms-transition: margin .4s ease-out;
}

.bs-canvas-left {
    left: 0;
    margin-left: -330px;
}

.bs-canvas-right {
    right: 0;
    margin-right: -330px;
}

.appNoteText {
    font-size: 0.8em;
}

.appNoteText label {
    font-weight: 600;
}

@media (max-width: 420px) {
    .textAnswer.answer {
        overflow-y: auto;
        max-width: 40ch;
        overflow-wrap: break-word;
    }
    .applicantNameTop {
        max-width: 30ch;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .applicantTable-applicant {
        max-width: 25ch;
        text-overflow: ellipsis;
        overflow: hidden;
    }    
}

@media (min-width: 300px) and (max-width: 374px) {
    .textAnswer.answer {
        max-width: 30ch;
    }
    .applicantNameTop {
        max-width: 20ch;
    }
    .applicantTable-applicant {
        max-width: 20ch;
    }
}